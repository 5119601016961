import React from 'react';
import { carleadCreationGetInitialProps, } from '@wkda/carlead-creation-components';
import {
  BaseLeadPage,
  CarleadBannerTextHIWWhyChooseWkdaFaq,
  CarleadLicensePlateSECustomerCounter
} from '@wkda/marketing-components';

function CarleadCreation() {
  return (
    <BaseLeadPage
      form={CarleadLicensePlateSECustomerCounter}
      content={CarleadBannerTextHIWWhyChooseWkdaFaq}
      headlineDesktop='carleadCreation.form.title'
    />
  );
}

CarleadCreation.getInitialProps = carleadCreationGetInitialProps;

export default CarleadCreation;
