// import { createBrowserInspector } from '@statelyai/inspect';
import { useQueryClient } from '@tanstack/react-query';
import { formNames } from '@wkda/carlead-creation-form';
import { useConstant, useModulesStore } from '@wkda/funnel-components';
import { useActorRef } from '@xstate/react';
import pick from 'lodash-es/pick';
import { useParams } from 'react-router-dom';
import { assertEvent, fromPromise } from 'xstate';
import { useCacheStorage } from '../../formCache';
import { useCarleadConfiguration } from '../../hooks/useCarleadCreationConfiguration';
import { useCarleadFieldChangedTracking } from '../../hooks/useCarleadFieldChangedTracking';
import { createLicensePlateSearchMachineConfig } from './licensePlateSearchMachineConfig';
import { pushSelectEventToDataLayerAction, questionnaire } from './shared/actions';
import { useSharedMachineServices } from './shared/services';

/**
 * How to debug a machine:
 * - install @statelyai/inspect
 * - uncomment the import of createBrowserInspector
 * - uncomment the inspector variable
 * - add the inspector to the useActorRef call
 * Remember to remove the inspector before committing the code
 */

// const inspector =
//   typeof window !== 'undefined' ? createBrowserInspector() : undefined;

const order = ['mainTypeDetail', 'mainTypeSub'];
export function useLicensePlateSearchService() {
  const queryClient = useQueryClient();
  const {
    carTypesModule,
    cjTrackingModule: {
      cjTracking
    }
  } = useModulesStore();
  const formMachine = createLicensePlateSearchMachineConfig();
  const cache = useCacheStorage();
  const {
    fullLocale,
    country
  } = useCarleadConfiguration();
  //@ts-expect-error
  const {
    dlp
  } = useParams();
  const languageParams = {
    locale: fullLocale,
    country
  };
  const trackLeadFieldChanged = useCarleadFieldChangedTracking();
  const actors = useSharedMachineServices();
  const configuredMachine = formMachine.provide({
    actions: {
      // action used for general SELECT action from user
      // and prefill
      pushDataLayerEvent(args) {
        pushSelectEventToDataLayerAction(args, trackLeadFieldChanged);
      },
      // action only used for questionnaire fields data preload
      pushToDataLayerCache(_, params) {
        trackLeadFieldChanged(params === null || params === void 0 ? void 0 : params.fieldName, 'cache');
      },
      trackLicensePlateValidation(_ref) {
        let {
          context: {
            values
          },
          event
        } = _ref;
        assertEvent(event, 'xstate.done.actor.validateMappingDataWithCarTypesData');
        const data = event.output;
        const parsed = Array.isArray(data) ? data : [];
        const lastFailed = parsed.slice(-1).join();
        const index = order.indexOf(lastFailed);
        const failedFieled = order.slice(0, index + 1);

        //@ts-expect-error
        cjTracking({
          type: 'cfe',
          appname: 'wkda',
          category: 'lead-creation',
          action: 'extra-fields-shown',
          timestamp: "" + Date.now() / 1000,
          licensePlate: values.licensePlate,
          funnelName: dlp,
          fields: Array.isArray(data) ? failedFieled.join(',') : '',
          userAgent: navigator.userAgent,
          source: '1'
        });
      },
      pushQuestionnaireDataLayerEvent: questionnaire.pushDataLayerEvent(trackLeadFieldChanged),
      pushQuestionToDataLayerCache: questionnaire.pushToDataLayerCache(trackLeadFieldChanged),
      syncQuestionnaireCookies: (args, params) => {
        questionnaire.syncAnswerToCookie(args, params);
      }
    },
    actors: {
      /*
       * TODO: Refactor
       * This service calls carTypes endpoints for mainTypeDetails and mainTypeSub,
       * picks the mapping values previously parsed and set in context state
       * and compare the result of each call with the mapping value of each
       * of those fields and returns an array with the names of the fields that
       * do not have a match with the mapping values, this array is then
       * set in context state and use to transition to a state that match this result
       */
      validateMappingDataWithCarTypesData: fromPromise(async function validateMappingDataWithCarTypesData(_ref2) {
        let {
          input: {
            mappingValues
          }
        } = _ref2;
        const detailsParams = pick(mappingValues, ['manufacturer', 'mainType', 'builtDate', 'bodyType']);
        const subParams = pick(mappingValues, ['manufacturer', 'mainType', 'builtDate', 'bodyType', 'mainTypeDetail']);
        const promises = Promise.all([queryClient.fetchQuery({
          queryKey: [formNames.mainTypeDetail, detailsParams],
          queryFn: () => {
            return carTypesModule.getMainTypesDetails({
              ...detailsParams,
              ...languageParams
            });
          }
        }), queryClient.fetchQuery({
          queryKey: [formNames.mainTypeSub, subParams],
          queryFn: () => {
            return carTypesModule.getMainTypesSub({
              ...subParams,
              ...languageParams
            });
          }
        })]).then(result => {
          const failedFields = result.map((item, idx) => {
            var _item$wkda;
            return !(item !== null && item !== void 0 && (_item$wkda = item.wkda) !== null && _item$wkda !== void 0 && _item$wkda[mappingValues[order[idx]]]) ? order[idx] : false;
          }).filter(Boolean);
          return failedFields;
        });
        return promises;
      }),
      fetchMainTypeDetails: actors.fetchMainTypeDetails,
      fetchMainTypeSub: actors.fetchMainTypeSub
    }
  });
  const finalMachine = useConstant(() => configuredMachine);
  const machineInterpreter = useActorRef(finalMachine, {
    // inspect: inspector?.inspect,
    input: {
      // DSB preload support
      // Feed with values object from dsb or empty
      // object if there is none or data does not match
      preloadedData: cache.getData()
    }
  });
  return machineInterpreter;
}