import { useSelector } from '@xstate/react';
import { useFormMachine } from '../../Form/CarleadCreationForm';
export function useDataWithMissingValue(name, label, data) {
  const machineFormService = useFormMachine();
  const invalidMappingField = useSelector(machineFormService, state => {
    var _state$context$invali;
    return (_state$context$invali = state.context.invalidMappingFields.find(i => {
      return i === name;
    })) !== null && _state$context$invali !== void 0 ? _state$context$invali : '';
  });
  const validationStatus = useSelector(machineFormService, state => {
    return state.context.validationStatus;
  });
  const mappingValues = useSelector(machineFormService, state => {
    return state.context.mappingValues;
  });
  const mappingValue = mappingValues[invalidMappingField];
  const newData = validationStatus === 'invalid' && invalidMappingField && mappingValue ? data.concat([{
    label,
    value: mappingValue,
    name: "_" + mappingValue
  }]) : data;
  return newData;
}