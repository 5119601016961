import { licensePlateMarketingPropertyMapper } from '@wkda/carlead-creation-form';
import { useSelector } from '@xstate/react';
export function useLicensePlateSearchProps(actor) {
  const isInvalid = useSelector(actor, state => {
    var _state$context;
    return (state === null || state === void 0 || (_state$context = state.context) === null || _state$context === void 0 ? void 0 : _state$context.validationStatus) === 'invalid';
  });
  const shouldRevalidate = useSelector(actor, state => {
    var _state$context2, _state$context3;
    return (state === null || state === void 0 || (_state$context2 = state.context) === null || _state$context2 === void 0 || (_state$context2 = _state$context2.values) === null || _state$context2 === void 0 ? void 0 : _state$context2.licensePlate) !== (state === null || state === void 0 || (_state$context3 = state.context) === null || _state$context3 === void 0 || (_state$context3 = _state$context3.previousValues) === null || _state$context3 === void 0 ? void 0 : _state$context3.licensePlate);
  });
  return {
    createSteps: [function licensePlateSearchSteps(_, formNames) {
      if (isInvalid && !shouldRevalidate) {
        return [[formNames.licensePlate, formNames.mileage, formNames.email, formNames.mainTypeDetail, formNames.mainTypeSub]];
      }
      return [[formNames.licensePlate, formNames.mileage, formNames.email]];
    }],
    marketingPropertiesMappers: [licensePlateMarketingPropertyMapper]
  };
}