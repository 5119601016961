import pick from 'lodash-es/pick';
import { assertEvent, assign, setup } from 'xstate';
import { isSelectEvent } from '../machineModels/eventGenerators';
import { assignDataOnSelectAction, assignOnPreloadAndClearAction, assignResetValuesAction, clearResetToAction, commitValuesAction, fillInputAction, focusOnEnterAction, resetValuesAndStackAction, selectFieldToFocusAction, selectWithPreloadedAction, sendSelectAfterResetAction, updateStackAction } from './shared/actions';
import { canModifyFieldGuard, canSelectFieldGuard, canSelectQuestionnaireFieldGuard, canUpdateFieldGuard, hasPreloadedDataForDataFieldGuard, hasPreloadedDataGuard, shouldFocusInputGuard, shouldTransitionToNextStateWithValueGuard, stepValueIsFromAnswersGuard, valueIsNotEmpty } from './shared/guards';
/*
 * LICENSE PLATE SEARCH Machine configuration
 * Parallel machine that will allow to have data fields (mainTypeDetail, mainTypeSub)
 * have it's own state isolated from other fields which make possible navigate and interact
 * with the fields while or after interacting with other fields, this fixes several bugs
 * like not being able to select mainTypeDetail or mainTypeSub after the mapping request
 * was on flight and interacting with other fields meanwhile and several, several others.
 *
 */

export function createLicensePlateSearchMachineConfig() {
  const machine = setup({
    types: {},
    actors: {},
    actions: {
      focusOnEnter: focusOnEnterAction,
      fillInput: fillInputAction(),
      //actions related to preloading the machine data for DSB support
      //action needed to for data fields preload
      //it will add the stepName to stepToPreload and value to stepValueToPreload
      //that will be used when calling selectWithPreloaded on the next microtick
      //also clear the fieldName from the preloaded data, this is needed to not allow
      //the guard enter this state again during the rest of th lifecycle
      assignOnPreloadAndClear: assignOnPreloadAndClearAction(),
      //This action is called right after the above action 👆🏼
      //it will pick from the context and send the select action
      //to go to the next logic state
      //this is not called to preload questionnaire fields
      selectWithPreloaded: selectWithPreloadedAction(),
      assignDataOnSelect: assignDataOnSelectAction(),
      updateStack: updateStackAction(),
      clearResetTo: clearResetToAction(),
      // Called after user select previous field
      // the state will go to reseting state and then
      // call this action to actually select the value for that field
      sendSelectAfterReset: sendSelectAfterResetAction(),
      assignResetValues: assignResetValuesAction(),
      resetValuesAndStack: resetValuesAndStackAction(),
      selectFieldToFocus: selectFieldToFocusAction(),
      commitValues: commitValuesAction,
      assignDataOnMappingValidation: assign(_ref => {
        let {
          context: {
            stack,
            dataFields,
            values
          },
          event
        } = _ref;
        assertEvent(event, 'VALIDATE_MAPPING');
        const {
          licensePlateSearchData
        } = event;
        return {
          mappingValues: {
            ...licensePlateSearchData
          },
          validationStatus: 'validating',
          invalidMappingFields: [],
          values: {
            ...values,
            manufacturer: '',
            mainType: '',
            builtDate: '',
            bodyType: '',
            mainTypeDetail: '',
            mainTypeSub: ''
          },
          stack: stack.filter(i => {
            return !dataFields.includes(i);
          })
        };
      }),
      assignDataOnValidLicensePlateMapping: assign(_ref2 => {
        let {
          context: {
            values,
            mappingValues
          },
          event
        } = _ref2;
        assertEvent(event, 'xstate.done.actor.validateMappingDataWithCarTypesData');
        return {
          invalidMappingFields: event.output,
          validationStatus: 'valid',
          // mainTypeDetails and mainTypeSub data from
          // mapping values match carTypes data for each field
          // put all of these as values in context state
          values: {
            ...values,
            ...mappingValues
          }
        };
      }),
      assignDataOnInvalidLicensePlateMapping: assign(_ref3 => {
        let {
          context: {
            mappingValues,
            dataFields,
            values
          },
          event
        } = _ref3;
        assertEvent(event, 'xstate.done.actor.validateMappingDataWithCarTypesData');
        const invalidFields = event.output;
        const validDataFields = dataFields.filter(i => {
          return !invalidFields.includes(i);
        });
        return {
          invalidMappingFields: invalidFields,
          validationStatus: 'invalid',
          // mainTypeDetails and/or mainTypeSub data from
          // mapping values do not match carTypes data for any of the fields
          // pick all correct values and add them to context state values
          // with these values the machine should transition to the correct
          // dataFields state
          values: {
            ...values,
            ...pick(mappingValues, validDataFields)
          },
          preloadedData: {}
        };
      }),
      assignDataOnFailedMappingValidation: assign(() => ({
        validationStatus: 'error'
      })),
      trackLicensePlateValidation: _args => {},
      pushToDataLayerCache: (_args, __params) => {},
      pushQuestionToDataLayerCache: (_args, __params) => {},
      pushQuestionnaireDataLayerEvent: (_args, __params) => {},
      syncQuestionnaireCookies: (_args, __params) => {},
      pushDataLayerEvent: (_args, __params) => {},
      blurOnEnter: (_args, __params) => {}
    },
    guards: {
      hasPreloadedData: hasPreloadedDataGuard,
      hasPreloadedDataForDataField: hasPreloadedDataForDataFieldGuard,
      canSelectField: canSelectFieldGuard,
      shouldFocusInput: shouldFocusInputGuard,
      canSelectQuestionnaireField: canSelectQuestionnaireFieldGuard,
      canModifyField: canModifyFieldGuard,
      shouldTransitionToNextStateWithValue: shouldTransitionToNextStateWithValueGuard,
      stepValueIsFromAnswers: stepValueIsFromAnswersGuard,
      canUpdateField: canUpdateFieldGuard,
      shouldModifyField: (_ref4, params) => {
        let {
          context: {
            values,
            stack
          },
          event
        } = _ref4;
        if (!isSelectEvent(event)) {
          return false;
        }
        const {
          fieldName,
          selected
        } = event;
        if (!params.allowedFields.includes(fieldName)) {
          return false;
        }
        const fieldPreviousValue = values[fieldName];
        return valueIsNotEmpty(fieldName) && valueIsNotEmpty(selected) && stack.includes(fieldName) && fieldPreviousValue !== selected;
      },
      shouldTransitionToNextStateWithValueForDataField: (_ref5, params) => {
        let {
          context: {
            values
          }
        } = _ref5;
        const fieldName = params.fieldName;
        const value = values[fieldName];
        return valueIsNotEmpty(value);
      },
      isValidationResponseValid(_ref6) {
        let {
          event
        } = _ref6;
        const evt = event;
        const data = evt.output;
        return Array.isArray(data) ? data.length === 0 : false;
      },
      isValidationResponseInvalid(_ref7) {
        let {
          event
        } = _ref7;
        const evt = event;
        const data = evt.output;
        return Array.isArray(data) ? data.length > 0 : false;
      }
    }
  }).createMachine({
    id: 'funnelForm',
    initial: 'normalFields',
    type: 'parallel',
    preserveActionOrder: true,
    context: _ref8 => {
      var _input$preloadedData;
      let {
        input
      } = _ref8;
      return {
        preloadedData: (_input$preloadedData = input.preloadedData) !== null && _input$preloadedData !== void 0 ? _input$preloadedData : {},
        stack: [],
        dataFields: ['manufacturer', 'mainType', 'builtDate', 'bodyType', 'mainTypeDetail', 'mainTypeSub'],
        otherFields: ['licensePlate', 'mileage', 'email'],
        questionnaireFields: ['SELLPOINT', 'SELL_TYPES'],
        resetTo: '',
        resetValue: '',
        resetType: '',
        values: {},
        stepToPreload: '',
        stepValueToPreload: '',
        updatingField: '',
        previousValues: {},
        validationStatus: 'none',
        mappingValues: {},
        invalidMappingFields: [],
        selectedFields: {},
        previousSelectedFieldsMap: {}
      };
    },
    on: {
      //External event call, it sets the context values as previousValues
      //it allows compare if the licensePlate has changed after a mapping validation
      COMMIT_VALUES: [{
        actions: [{
          type: 'commitValues'
        }]
      }],
      //External event call, it sets the data needed for validation
      //to the context state
      VALIDATE_MAPPING: [{
        target: '.dataFields.validateMapping',
        actions: [{
          type: 'assignDataOnMappingValidation'
        }]
      }]
    },
    states: {
      dataFields: {
        initial: 'waiting',
        id: 'dataFields',
        on: {
          SELECT: [{
            target: '#dataFields.calculateStateToReset',
            guard: {
              type: 'shouldModifyField',
              params: {
                allowedFields: ['mainTypeDetail', 'mainTypeSub']
              }
            },
            actions: [{
              type: 'assignResetValues'
            }]
          }]
        },
        states: {
          waiting: {},
          calculateStateToReset: {
            always: [{
              target: 'mainTypeDetail.hist',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'mainTypeDetail'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'dataFields'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }, {
              target: 'mainTypeSub.hist',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'mainTypeSub'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'dataFields'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }]
          },
          mainTypeDetail: {
            initial: 'loading',
            states: {
              loading: {
                invoke: {
                  id: 'fetchMainTypeDetails',
                  src: 'fetchMainTypeDetails',
                  input: _ref9 => {
                    let {
                      context: {
                        values
                      }
                    } = _ref9;
                    return {
                      values
                    };
                  },
                  onDone: [{
                    guard: {
                      type: 'hasPreloadedDataForDataField',
                      params: {
                        fieldName: 'mainTypeDetail'
                      }
                    },
                    actions: [{
                      type: 'assignOnPreloadAndClear',
                      params: {
                        fieldName: 'mainTypeDetail'
                      }
                    }, {
                      type: 'selectWithPreloaded'
                    }],
                    target: 'loaded'
                  }, {
                    target: 'loaded'
                  }]
                }
              },
              loaded: {
                always: [{
                  guard: {
                    type: 'shouldTransitionToNextStateWithValueForDataField',
                    params: {
                      fieldName: 'mainTypeDetail'
                    }
                  },
                  target: '#dataFields.mainTypeSub',
                  actions: [{
                    type: 'updateStack',
                    params: {
                      fieldName: 'mainTypeDetail',
                      nextField: 'mainTypeSub'
                    }
                  }]
                }],
                on: {
                  SELECT: [{
                    target: '#dataFields.mainTypeSub',
                    guard: {
                      type: 'canSelectField',
                      params: {
                        fieldName: 'mainTypeDetail'
                      }
                    },
                    actions: [{
                      type: 'assignDataOnSelect',
                      params: {
                        nextField: 'mainTypeSub'
                      }
                    }, {
                      type: 'pushDataLayerEvent'
                    }]
                  }]
                },
                entry: [{
                  type: 'focusOnEnter',
                  params: {
                    fieldName: 'mainTypeDetail'
                  }
                }],
                exit: [{
                  type: 'clearResetTo'
                }]
              },
              hist: {
                type: 'history'
              }
            }
          },
          mainTypeSub: {
            initial: 'loading',
            states: {
              loading: {
                invoke: {
                  id: 'fetchMainTypeSub',
                  src: 'fetchMainTypeSub',
                  input: _ref10 => {
                    let {
                      context: {
                        values
                      }
                    } = _ref10;
                    return {
                      values
                    };
                  },
                  onDone: [{
                    guard: {
                      type: 'hasPreloadedDataForDataField',
                      params: {
                        fieldName: 'mainTypeSub'
                      }
                    },
                    actions: [{
                      type: 'assignOnPreloadAndClear',
                      params: {
                        fieldName: 'mainTypeSub'
                      }
                    }, {
                      type: 'selectWithPreloaded'
                    }],
                    target: 'loaded'
                  }, {
                    target: 'loaded'
                  }]
                }
              },
              loaded: {
                always: [{
                  guard: {
                    type: 'shouldTransitionToNextStateWithValueForDataField',
                    params: {
                      fieldName: 'mainTypeSub'
                    }
                  },
                  target: '#dataFields.waiting',
                  actions: [{
                    type: 'updateStack',
                    params: {
                      fieldName: 'mainTypeSub',
                      nextField: 'waiting'
                    }
                  }]
                }],
                on: {
                  SELECT: [{
                    target: '#dataFields.waiting',
                    guard: {
                      type: 'canSelectField',
                      params: {
                        fieldName: 'mainTypeSub'
                      }
                    },
                    actions: [{
                      type: 'assignDataOnSelect',
                      params: {
                        nextField: 'waiting'
                      }
                    }, {
                      type: 'pushDataLayerEvent'
                    }]
                  }]
                },
                entry: [{
                  type: 'focusOnEnter',
                  params: {
                    fieldName: 'mainTypeSub'
                  }
                }],
                exit: [{
                  type: 'clearResetTo'
                }]
              },
              hist: {
                type: 'history'
              }
            }
          },
          validateMapping: {
            initial: 'validate',
            states: {
              validate: {
                invoke: {
                  id: 'validateMappingDataWithCarTypesData',
                  src: 'validateMappingDataWithCarTypesData',
                  input: _ref11 => {
                    let {
                      context: {
                        mappingValues
                      }
                    } = _ref11;
                    return {
                      mappingValues
                    };
                  },
                  onDone: [{
                    actions: [{
                      type: 'trackLicensePlateValidation'
                    }, {
                      type: 'assignDataOnValidLicensePlateMapping'
                    }],
                    guard: {
                      type: 'isValidationResponseValid'
                    }
                  }, {
                    target: '#dataFields.mainTypeDetail',
                    actions: [{
                      type: 'trackLicensePlateValidation'
                    }, {
                      type: 'assignDataOnInvalidLicensePlateMapping'
                    }],
                    guard: {
                      type: 'isValidationResponseInvalid'
                    }
                  }],
                  onError: [{
                    target: '#dataFields.waiting',
                    actions: [{
                      type: 'assignDataOnFailedMappingValidation'
                    }]
                  }]
                }
              }
            }
          }
        }
      },
      normalFields: {
        initial: 'licensePlate',
        id: 'normalFields',
        on: {
          SELECT: [{
            target: '#normalFields.calculateStateToReset',
            guard: {
              type: 'shouldModifyField',
              params: {
                allowedFields: ['licensePlate', 'mileage', 'SELLPOINT', 'SELL_TYPES', 'email']
              }
            },
            actions: [{
              type: 'assignResetValues'
            }]
          }],
          FOCUS_INPUT: [{
            target: '#normalFields.calculateStateToFocus',
            guard: {
              type: 'shouldFocusInput'
            },
            actions: [{
              type: 'selectFieldToFocus'
            }]
          }]
        },
        states: {
          calculateStateToReset: {
            always: [{
              target: 'licensePlate',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'licensePlate'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'none'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }, {
              target: 'mileage',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'mileage'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'none'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }, {
              target: 'SELLPOINT',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'SELLPOINT'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'questionnaireFields'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }, {
              target: 'SELL_TYPES',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'SELL_TYPES'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'questionnaireFields'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }, {
              target: 'email',
              guard: {
                type: 'canModifyField',
                params: {
                  field: 'email'
                }
              },
              actions: [{
                type: 'resetValuesAndStack',
                params: {
                  nodeType: 'none'
                }
              }, {
                type: 'sendSelectAfterReset'
              }]
            }]
          },
          calculateStateToFocus: {
            always: [{
              target: 'licensePlate',
              guard: {
                type: 'canUpdateField',
                params: {
                  fieldName: 'licensePlate'
                }
              }
            }, {
              target: 'mileage',
              guard: {
                type: 'canUpdateField',
                params: {
                  fieldName: 'mileage'
                }
              }
            }, {
              target: 'email',
              guard: {
                type: 'canUpdateField',
                params: {
                  fieldName: 'email'
                }
              }
            }]
          },
          licensePlate: {
            entry: [{
              type: 'focusOnEnter',
              params: {
                fieldName: 'licensePlate'
              }
            }],
            exit: [{
              type: 'clearResetTo'
            }],
            always: [{
              guard: {
                type: 'hasPreloadedData',
                params: {
                  fieldName: 'licensePlate'
                }
              },
              actions: [{
                type: 'assignOnPreloadAndClear',
                params: {
                  fieldName: 'licensePlate'
                }
              }, {
                type: 'selectWithPreloaded'
              }]
            }, {
              target: 'mileage',
              guard: {
                type: 'shouldTransitionToNextStateWithValue',
                params: {
                  fieldName: 'licensePlate'
                }
              },
              actions: [{
                type: 'updateStack',
                params: {
                  fieldName: 'licensePlate',
                  nextField: 'mileage'
                }
              }]
            }],
            on: {
              SELECT: [{
                target: 'mileage',
                guard: {
                  type: 'canSelectField',
                  params: {
                    fieldName: 'licensePlate'
                  }
                },
                actions: [{
                  type: 'assignDataOnSelect',
                  params: {
                    nextField: 'mileage'
                  }
                }, {
                  type: 'pushDataLayerEvent'
                }]
              }],
              FILL_INPUT: [{
                target: 'licensePlate',
                actions: [{
                  type: 'fillInput',
                  params: {
                    fieldName: 'licensePlate',
                    nextField: 'mileage'
                  }
                }]
                // internal: true,
              }]
            }
          },
          mileage: {
            entry: [{
              type: 'focusOnEnter',
              params: {
                fieldName: 'mileage'
              }
            }],
            exit: [{
              type: 'clearResetTo'
            }],
            always: [{
              guard: {
                type: 'hasPreloadedData',
                params: {
                  fieldName: 'mileage'
                }
              },
              actions: [{
                type: 'assignOnPreloadAndClear',
                params: {
                  fieldName: 'mileage'
                }
              }, {
                type: 'selectWithPreloaded'
              }]
            }, {
              guard: {
                type: 'shouldTransitionToNextStateWithValue',
                params: {
                  fieldName: 'mileage'
                }
              },
              target: 'SELLPOINT',
              actions: [{
                type: 'updateStack',
                params: {
                  fieldName: 'mileage',
                  nextField: 'SELLPOINT'
                }
              }]
            }],
            on: {
              SELECT: [{
                target: 'SELLPOINT',
                guard: {
                  type: 'canSelectField',
                  params: {
                    fieldName: 'mileage'
                  }
                },
                actions: [{
                  type: 'assignDataOnSelect',
                  params: {
                    nextField: 'SELLPOINT'
                  }
                }, {
                  type: 'pushDataLayerEvent'
                }]
              }],
              FILL_INPUT: [{
                target: 'mileage',
                actions: [{
                  type: 'fillInput',
                  params: {
                    fieldName: 'mileage',
                    nextField: 'SELLPOINT'
                  }
                }]
                // internal: true,
              }]
            }
          },
          SELLPOINT: {
            entry: [{
              type: 'focusOnEnter',
              params: {
                fieldName: 'SELLPOINT'
              }
            }],
            exit: [{
              type: 'clearResetTo'
            }],
            always: [{
              guard: {
                type: 'hasPreloadedData',
                params: {
                  fieldName: 'SELLPOINT'
                }
              },
              actions: [{
                type: 'assignOnPreloadAndClear',
                params: {
                  fieldName: 'SELLPOINT'
                }
              }, {
                type: 'selectWithPreloaded'
              }]
            }, {
              target: 'SELL_TYPES',
              guard: {
                type: 'stepValueIsFromAnswers',
                params: {
                  fromAnswers: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
                  fieldName: 'SELLPOINT'
                }
              },
              actions: [{
                type: 'updateStack',
                params: {
                  fieldName: 'SELLPOINT',
                  nextField: 'SELL_TYPES'
                }
              }, {
                type: 'pushQuestionToDataLayerCache',
                params: {
                  fieldName: 'SELLPOINT',
                  nextField: 'SELL_TYPES',
                  guards: {
                    SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
                    email: ['SELLPOINT_NOT_PLANNED']
                  }
                }
              }]
            }, {
              target: 'email',
              guard: {
                type: 'stepValueIsFromAnswers',
                params: {
                  fromAnswers: ['SELLPOINT_NOT_PLANNED'],
                  fieldName: 'SELLPOINT'
                }
              },
              actions: [{
                type: 'updateStack',
                params: {
                  fieldName: 'SELLPOINT',
                  nextField: 'email'
                }
              }, {
                type: 'pushQuestionToDataLayerCache',
                params: {
                  fieldName: 'SELLPOINT',
                  nextField: 'email',
                  guards: {
                    SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
                    email: ['SELLPOINT_NOT_PLANNED']
                  }
                }
              }]
            }],
            on: {
              SELECT: [{
                target: 'SELL_TYPES',
                guard: {
                  type: 'canSelectQuestionnaireField',
                  params: {
                    fromAnswers: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
                    fieldName: 'SELLPOINT'
                  }
                },
                actions: [{
                  type: 'assignDataOnSelect',
                  params: {
                    nextField: 'SELL_TYPES'
                  }
                }, {
                  type: 'pushQuestionnaireDataLayerEvent',
                  params: {
                    nextField: 'SELL_TYPES',
                    guards: {
                      SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
                      email: ['SELLPOINT_NOT_PLANNED']
                    }
                  }
                }]
              }, {
                target: 'email',
                guard: {
                  type: 'canSelectQuestionnaireField',
                  params: {
                    fromAnswers: ['SELLPOINT_NOT_PLANNED'],
                    fieldName: 'SELLPOINT'
                  }
                },
                actions: [{
                  type: 'assignDataOnSelect',
                  params: {
                    nextField: 'email'
                  }
                }, {
                  type: 'pushQuestionnaireDataLayerEvent',
                  params: {
                    nextField: 'email',
                    guards: {
                      SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
                      email: ['SELLPOINT_NOT_PLANNED']
                    }
                  }
                }]
              }]
            }
          },
          SELL_TYPES: {
            entry: [{
              type: 'focusOnEnter',
              params: {
                fieldName: 'SELL_TYPES'
              }
            }],
            exit: [{
              type: 'clearResetTo'
            }],
            always: [{
              guard: {
                type: 'hasPreloadedData',
                params: {
                  fieldName: 'SELL_TYPES'
                }
              },
              actions: [{
                type: 'assignOnPreloadAndClear',
                params: {
                  fieldName: 'SELL_TYPES'
                }
              }, {
                type: 'selectWithPreloaded'
              }]
            }, {
              target: 'email',
              guard: {
                type: 'stepValueIsFromAnswers',
                params: {
                  fromAnswers: ['PRIVATE', 'DEALER'],
                  fieldName: 'SELL_TYPES'
                }
              },
              actions: [{
                type: 'updateStack',
                params: {
                  fieldName: 'SELL_TYPES',
                  nextField: 'email'
                }
              }]
            }],
            on: {
              SELECT: [{
                target: 'email',
                guard: {
                  type: 'canSelectQuestionnaireField',
                  params: {
                    fromAnswers: ['PRIVATE', 'DEALER'],
                    fieldName: 'SELL_TYPES'
                  }
                },
                actions: [{
                  type: 'assignDataOnSelect',
                  params: {
                    nextField: 'email'
                  }
                }, {
                  type: 'pushDataLayerEvent'
                }]
              }]
            }
          },
          email: {
            entry: [{
              type: 'focusOnEnter',
              params: {
                fieldName: 'email'
              }
            }],
            exit: [{
              type: 'clearResetTo'
            }],
            always: [{
              guard: {
                type: 'hasPreloadedData',
                params: {
                  fieldName: 'email'
                }
              },
              actions: [{
                type: 'assignOnPreloadAndClear',
                params: {
                  fieldName: 'email'
                }
              }, {
                type: 'selectWithPreloaded'
              }]
            }, {
              target: 'submit',
              guard: {
                type: 'shouldTransitionToNextStateWithValue',
                params: {
                  fieldName: 'email'
                }
              },
              actions: [{
                type: 'updateStack',
                params: {
                  fieldName: 'email',
                  nextField: 'submit'
                }
              }]
            }],
            on: {
              SELECT: [{
                target: 'submit',
                guard: {
                  type: 'canSelectField',
                  params: {
                    fieldName: 'email'
                  }
                },
                actions: [{
                  type: 'assignDataOnSelect',
                  params: {
                    nextField: 'submit'
                  }
                }, {
                  type: 'pushDataLayerEvent'
                }]
              }],
              FILL_INPUT: [{
                target: 'email',
                actions: [{
                  type: 'fillInput',
                  params: {
                    fieldName: 'email',
                    nextField: 'submit'
                  }
                }]
                // internal: true,
              }]
            }
          },
          submit: {
            entry: [{
              type: 'blurOnEnter'
            }],
            on: {}
          }
        }
      }
    }
  });
  return machine;
}